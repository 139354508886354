.register_form {
  width: 350px;
}

.success-container {
  text-align: center;
  margin-top: 20px;
}

.title-success {
  font-size: 24px;
  color: rgba(0,0,0,.85);
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 16px;
  margin-top: 32px;
}

.icon-success {
  font-size: 64px;
  color: #8AC441;
}

.description-success {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0,0,0,.45);
  margin-bottom: 24px;
}

.register_button {
  background-color: #095D7C;
  color: #fff;
  border-color: #095D7C;
  width: 100%;
  padding: 8px 32px 8px 32px;
  height: 44px;
  align-self: center;
  border-radius: 22px;
  border-width: 2px;
  font-weight: 800;
  font-size: 0.875rem;
}

.register_button:hover {
  background-color: #277E9F;
  border-color: #277E9F;
};

.register_button:disabled {
  background-color: #E9E9EA;
  color: #45484E;
  border-color: #45484E;
};

.pc-info {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #277E9F;
  border-radius: 20px;
  width: 35px;
  height: 35px;
  margin-top: 6px;
  border-width: 1px;
  border-style: solid;
}